<template>
    <ion-app>
        <div v-if="isPlatform('desktop')" class="desktop__content">
            <router-view></router-view>
        </div>

        <ion-split-pane v-else content-id="main-content">
            <ion-menu content-id="main-content" type="overlay" side="end">
                <ion-content>
                    <SidebarMenu />
                </ion-content>
            </ion-menu>
            <ion-router-outlet id="main-content"></ion-router-outlet>
        </ion-split-pane>
    </ion-app>
</template>

<script setup>
import { isPlatform } from '@ionic/vue';

import {
    IonApp,
    IonContent,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
} from '@ionic/vue';

import SidebarMenu from "@/partials/SidebarMenu.vue";
import {StatusBar} from '@capacitor/status-bar'
import {onMounted} from "vue";

onMounted(() => {
    if(!isPlatform('desktop')) {
        const setStatusBarStyleDark = async () => {
            await StatusBar.setBackgroundColor({color: '#162352'})
            // await StatusBar.setBackgroundColor({color: '#f15a24'})
        };
        setStatusBarStyleDark()
    }
})
</script>

<style lang="scss" scoped>
ion-content {
    --background: #fff
}

ion-menu {
    --background: #fff
}

</style>
